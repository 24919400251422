import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const FullPageStyles = styled.div`
  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 30px;
    text-decoration: underline;
  }

  a {
    text-decoration: none;

    &:hover {
      color: var(--link-hover);
    }
  }

  @media all and (min-width: 900px) {
    font-size: inherit;
    h1 {
      font-size: 4rem;
      text-align: left;
    }
  }
`

const OfficerListStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  padding-bottom: 50px;

  @media all and (min-width: 900px) {
    justify-content: flex-start;
  }
`

const OfficerStyles = styled.div`
  display: grid;
  border: 1px solid black;
  box-shadow: 2px 2px 5px #999;
  width: 350px;
  @supports not (grid-template-rows: subgrid) {
    --rows: auto auto 1fr;
  }
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 3;
  grid-gap: 1rem;
  h2,
  p {
    margin: 5px 0;
  }

  .image {
    width: 100%;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 140px;
      width: 100px;
      overflow: hidden;
      /* margin: 0 auto; */

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .title,
  .officer-info {
    text-align: center;
  }

  .title {
    font-size: 140%;
    margin: 0;
    padding: 10px 10px;
  }

  .officer-info {
    padding: 5px 10px;
  }

  .officer-info {
    .name {
      font-weight: bold;
    }
  }
`

const SimpleOfficerListStyles = styled.div``

const SimpleOfficerStyles = styled.div`
  text-align: center;

  padding-bottom: 20px;
  h4 {
    padding: 0;
    margin: 0;
    font-weight: bold;
    padding-bottom: 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  @media all and (min-width: 900px) {
    text-align: left;

    ul {
      padding-left: 3rem;

      li {
        padding-bottom: 8px;
      }
    }
  }
`

const createOfficerObj = officerList =>
  officerList.reduce((obj, officer) => {
    const { category } = officer

    if (!obj[category]) {
      obj[category] = []
    }

    obj[category].push(officer)
    return obj
  }, {})

const orderExec = officerList => {
  const order = [
    'President',
    'Immediate Past President',
    'Vice President',
    'Secretary',
    'Treasurer',
  ]

  const finalArray = order.map(title => {
    const curOfficer = officerList.find(o => o.title === title)
    return curOfficer
  })

  officerList.forEach(o => {
    if (!order.includes(o.title)) {
      finalArray.push(o)
    }
  })

  return finalArray
}

function SingleOfficer({ officer }) {
  return (
    <OfficerStyles>
      <h2 className="title">{officer.title}</h2>
      <div className="image">
        <div className="image-container">
          <Img
            fixed={officer.image.asset.fixed}
            alt={`${officer.title}: ${officer.name}`}
          />
        </div>
      </div>
      <div className="officer-info">
        <p className="name">{officer.name}</p>
        <p>
          <a href={`mailto:${officer.email}`}>{officer.email}</a>
        </p>
        <p>
          <a href={`tel:${officer.number}`}>{officer.number}</a>
        </p>
      </div>
    </OfficerStyles>
  )
}

function SimpleOfficer({ officer }) {
  return (
    <SimpleOfficerStyles>
      <h4>{officer.title}</h4>
      <ul>
        <li>{officer.name}</li>
        <li>
          <a href={`mailto:${officer.email}`}>{officer.email}</a>
        </li>
        <li>
          <a href={`tel:${officer.number}`}>{officer.number}</a>
        </li>
      </ul>
    </SimpleOfficerStyles>
  )
}

function BoardCategory({ isSimple = false, officersArr = [], title }) {
  if (!officersArr || officersArr.length === 0) return ''

  const titleDiv = <h1 className="title subtitle">{title}</h1>
  let officerDiv = ''
  if (isSimple) {
    officerDiv = (
      <SimpleOfficerListStyles>
        {officersArr.map(officer => (
          <SimpleOfficer key={officer.id} officer={officer} />
        ))}
      </SimpleOfficerListStyles>
    )
  } else {
    officerDiv = (
      <OfficerListStyles>
        {officersArr.map(officer => (
          <SingleOfficer key={officer.id} officer={officer} />
        ))}
      </OfficerListStyles>
    )
  }

  return (
    <>
      {titleDiv}
      {officerDiv}
    </>
  )
}

export default function DistrictOfficers({ data }) {
  const officerList = data.officers.nodes

  const officerObj = createOfficerObj(officerList)

  const exec = orderExec(officerObj['Executive Committee'])
  const board = officerObj['Board Member at Large']
  const directors = officerObj.Directors
  const other = officerObj['Other Appointments and Positions']

  return (
    <FullPageStyles className="content-width">
      <BoardCategory officersArr={exec} title="ExecutiveCommittee" />
      <BoardCategory officersArr={directors} title="Directors" />
      <BoardCategory officersArr={board} title="Board Member at Large" />
      <BoardCategory
        officersArr={other}
        title="Other Appointments and Positions"
        isSimple
      />
    </FullPageStyles>
  )
}

export const PageContent = graphql`
  query DistrictOfficerQuery {
    officers: allSanityBoardMember {
      nodes {
        email
        id
        name
        number
        title
        category
        image {
          asset {
            fixed(width: 100) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  }
`
